import { useState } from 'react';
import BalanceSheetKpis from "./kpis";
import BalanceSheetTable from "./table";
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay';

const BalanceSheet = ({ from, to }: { from: string; to: string }) => {
  const clicks = 0;
  const shouldShowTable = clicks >= 8;

  return (
    <>
      {shouldShowTable ? (
        <>
          <BalanceSheetKpis mb={2} />
          <BalanceSheetTable from={from} to={to}/>
        </>
      ) : (
        <NoDataDisplay
          mt={10}
          title="Coming Soon!"
          description=" "
        />
      )}
    </>
  );
};

export default BalanceSheet
