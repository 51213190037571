import { useState } from 'react';
import NoDataDisplay from '../../../components/noDataDisplay/noDataDisplay';

const CashFlow = ({ from, to }: { from: string; to: string }) => {
  return (
    <NoDataDisplay
      mt={10}
      title="Coming Soon!"
      description=" "
    />
  );
};

export default CashFlow
