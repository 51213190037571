import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import { isUsingMobile } from '../../helpers/utils'

import ContentHeader from '../../components/content-header'
import ReportsTabs from './tabs'
import ReportsFilters from './filters'
import ProfitLoss from './profit-loss/profit-loss'
import { useDispatch, useSelector } from 'react-redux'
import { saveBalanceSheet, saveProfitAndLoss } from '../../redux/actions/report.actions'
import { AppState } from '../../redux/store'
import moment from 'moment'
import BalanceSheet from './balance-sheet/balance-sheet'
import CashFlow from './cash-flow/cash-flow'

const FinancialStatements = () => {
  const dispatch = useDispatch()

  const profitAndLossData = useSelector((state: AppState) => state.reportReducer.profitAndLoss)
  const balanceSheetData = useSelector((state: AppState) => state.reportReducer.balanceSheet)

  const [selectedTab, setSelectedTab] = useState('PROFIT_LOSS')
  const [filterPeriod, setFilterPeriod] = useState({
    from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    dispatch(
      saveProfitAndLoss(
        moment(filterPeriod.from).format('YYYY-MM-DD'),
        moment(filterPeriod.to).format('YYYY-MM-DD'),
      ),
    )
    dispatch(
      saveBalanceSheet(
        moment(filterPeriod.from).format('YYYY-MM-DD'),
        moment(filterPeriod.to).format('YYYY-MM-DD'),
      ),
    )
  }, [])

  return (
    <>
      <ContentHeader title='Financial Statements' />

      <Grid container>
        <Grid item>
          <ReportsTabs
            selectedTab={selectedTab}
            onSelectedTabChange={(evt, value) => setSelectedTab(value)}
          />
        </Grid>
        <Grid item flexGrow={2} mb={isUsingMobile() ? 2 : undefined}>
          <ReportsFilters
            setFilterPeriod={setFilterPeriod}
            filterPeriod={filterPeriod}
            performRequest={(from, to) =>
              {
                const formattedFrom = moment(from).format('YYYY-MM-DD');
                const formattedTo = moment(to).format('YYYY-MM-DD');
                if (selectedTab === 'PROFIT_LOSS') {
                  dispatch(saveProfitAndLoss(formattedFrom, formattedTo));
                } else if (selectedTab === 'BALANCE_SHEET') {
                  dispatch(saveBalanceSheet(formattedFrom, formattedTo));
                }
              }
            }
            onUpdateClick={() =>
              {
                const formattedFrom = moment(filterPeriod.from).format('YYYY-MM-DD');
                const formattedTo = moment(filterPeriod.to).format('YYYY-MM-DD');
                if (selectedTab === 'PROFIT_LOSS') {
                  dispatch(saveProfitAndLoss(formattedFrom, formattedTo));
                } else if (selectedTab === 'BALANCE_SHEET') {
                  dispatch(saveBalanceSheet(formattedFrom, formattedTo));
                }
              }
            }
          />
        </Grid>
      </Grid>
      {selectedTab === 'PROFIT_LOSS' && profitAndLossData && (
        <ProfitLoss from={filterPeriod.from} to={filterPeriod.to} />
      )}
      {selectedTab === 'BALANCE_SHEET' && balanceSheetData && (
        <BalanceSheet from={filterPeriod.from} to={filterPeriod.to} />
      )}
      {selectedTab === 'CASH_FLOW' && (
        <CashFlow from={filterPeriod.from} to={filterPeriod.to} />
      )}
    </>
  )
}

export default FinancialStatements
